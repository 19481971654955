import { rgba } from 'polished';
import { css, keyframes } from 'styled-components';

import { generateFluidFontSize } from '@/components/v2/UtilityCss/utils';

import { palette } from './palette';

export const ColorVars = {
  GRAY: '#f8fafb',
  BLACK: '#01011b',
  WHITE: '#ffffff',
  PURPLE: '#473982',
  LIGHT_PURPLE: '#ad8eb6',
  LIGHT_PINK: '#f5c0c0',
  LIGHT_GREEN: '#43D59D',
  LIGHT_BLUE: '#8ABBFF',
  DARK_GREEN: '#1C866B',
  DARK_BLUE: '#215DB0',
  OBSIDIAN: '#14141C',
  VIOLET_TOPAZ: '#5F509D',
  LIGHT_VIOLET_TOPAZ: '#EAE8F0',
  LIGHTEST_VIOLET_TOPAZ: '#faf7ff'
};

export const MEDIA_QUERIES = {
  MOBILE: 450,
  SMALL: 600,
  MEDIUM: 800,
  LARGE: 1000,
  EXTRA_LARGE: 1250
};

export const FontSize = {
  EXTRA_SMALL: '10px',
  SMALL: '12px',
  DEFAULT: '14px',
  MEDIUM: '16px',
  LARGE: '20px',
  EXTRA_LARGE: '24px',
  h0: generateFluidFontSize(42, 74),
  h1: generateFluidFontSize(42, 60),
  h2: generateFluidFontSize(30, 40),
  h3: generateFluidFontSize(20, 28),
  h4: 'calc(20rem / 16)',
  h5: 'calc(14rem / 16)',
  h6: generateFluidFontSize(14, 12),
  body: {
    xs: 'calc(10rem / 16)',
    s: 'calc(12rem / 16)',
    base: 'calc(14rem / 16)',
    m: '1rem',
    // l: generateFluidFontSize(20, 20),
    l: 'calc(20rem / 16)',
    xl: generateFluidFontSize(20, 24)
  }
};

export const LineHeight = {
  SMALL: '18px',
  DEFAULT: '22px',
  MEDIUM: '24px',
  LARGE: '30px',
  EXTRA_LARGE: '34px',
  HEADING: '1.3',
  h: 1.3,
  h5: 1.571,
  h6: 1.571,
  body: {
    s: 1.5,
    base: 1.571,
    m: 1.5,
    l: 1.5,
    xl: 1.5
  }
};

export const LetterSpacing = {
  HEADING: '-0.025em',
  h0: '-0.025em',
  h1: '-0.0308em',
  h2: '-0.025em',
  h3: '-0.071em',
  h4: '-0.025em',
  h5: '-0.025em',
  h6: '0.214em'
};

export const FontWeight = {
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
  h0: 700,
  h1: 700,
  h2: 800,
  h3: 800,
  h4: 600,
  h5: 600,
  h6: 700,
  body: 400,
  mono: 400
};

export const MaxWidth = {
  BLOG: '740px',
  PAGE_NARROW: '1100px',
  PAGE: '1240px',
  PAGE_WIDE: '1300px'
};

export const FontFamily = {
  DISPLAY:
    'PP Formula SemiExtended, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  HEADING:
    'PP Formula, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  SANS: 'Cinetype, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  MONO: 'IBM Plex Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  CODE: 'IBM Plex Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  MONO_ALT:
    'Cinetype Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
};

const Card = css`
  background: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.fontColor.LOUD};
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.03),
    0 8px 16px 0 rgba(0, 0, 0, 0.05),
    0 1px 4px 0 rgba(0, 0, 0, 0.1);
`;

const PhotoOverlay = css`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    mix-blend-mode: overlay;
    z-index: 1;
    background: linear-gradient(
      180deg,
      ${({ theme }) => rgba(theme.highlightColor, 0.7)} 0%,
      ${({ theme }) => rgba(theme.highlightColor, 0.1)} 100%
    );
  }
`;

const Banner = css`
  align-self: flex-start;
  display: flex;
  align-items: center;
  height: 20px;
  border: none;

  span {
    font-family: ${({ theme }) => theme.fontFamily.MONO};
    display: flex;
    height: 12px;
    letter-spacing: 2px;
    font-size: ${({ theme }) => theme.fontSize.SMALL};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.LIGHT_PURPLE};
    font-weight: ${({ theme }) => theme.fontWeight.BOLD};
    line-height: 12px;
  }
`;

const MagicColors = {
  backgroundGradient: 'linear-gradient(90deg, #6646A80C 0%, #9757AB06 100%)',
  primaryColor: '#F5C0C0',
  secondaryColor: '#F5C0C0',
  tertiaryColor: '#F5C0C0',
  removingColor: '#c84654'
};

const AppUI = {
  borderColor: { DEFAULT: '#dadde6', MUTED: '#ECEDF2' },
  outlinedBorderColor: {
    DEFAULT: rgba(ColorVars.LIGHT_PINK, 0.4),
    MUTED: rgba(ColorVars.LIGHT_PINK, 0.2)
  },
  fontColor: { DEFAULT: '#01011B', MUTED: '#717A94' },
  backgroundColor: {
    MUTED: '#F8FAFB',
    DEFAULT: '#FFFFFF'
  },
  primaryColor: '#254685',
  magic: MagicColors,
  intent: {
    DANGER: 'red',
    SUCCESS: 'green'
  }
};

export const textures = {
  noise: {
    img: "url('/images/bkg-noise.webp')",
    size: '200px 200px',
    mixBlendMode: 'soft-light',
    opacity: 0.325
  }
};

export const SHARED_THEME_STYLES = {
  animation: {
    DURATION: '0.25s',
    EASING: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
    durationMedium: '0.25s',
    durationMediumMs: 250
  },
  appUI: AppUI,
  banner: Banner,
  borderRadius: '3px',
  card: Card,
  colors: ColorVars,
  ellipsis: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  fontFamily: FontFamily,
  fontSize: FontSize,
  fontWeight: FontWeight,
  lineHeight: LineHeight,
  letterSpacing: LetterSpacing,
  maxWidth: MaxWidth,
  mediaQuery: {
    MOBILE: `${MEDIA_QUERIES.MOBILE}px`,
    SMALL: `${MEDIA_QUERIES.SMALL}px`,
    MEDIUM: `${MEDIA_QUERIES.MEDIUM}px`,
    LARGE: `${MEDIA_QUERIES.LARGE}px`,
    EXTRA_LARGE: `${MEDIA_QUERIES.EXTRA_LARGE}px`
  },
  mediaQueryString: {
    MOBILE: `only screen and (max-width: ${MEDIA_QUERIES.MOBILE}px)`,
    SMALL: `only screen and (max-width: ${MEDIA_QUERIES.SMALL}px)`,
    MEDIUM: `only screen and (max-width: ${MEDIA_QUERIES.MEDIUM}px)`,
    LARGE: `only screen and (max-width: ${MEDIA_QUERIES.LARGE}px)`,
    EXTRA_LARGE: `only screen and (max-width: ${MEDIA_QUERIES.EXTRA_LARGE}px)`
  },
  photoOverlay: PhotoOverlay,
  toutSpacing: {
    DEFAULT: '80px 40px',
    VERTICAL: '80px',
    HORIZONTAL: '40px'
  },
  palette,
  textures
};

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const bounceHorizontally = keyframes`
  from {
    transform: translateX(-2px);
  }
  to {
    transform: translateX(2px);
  }
`;
